<template>
	<div class="animated fadeIn">
		<b-card class="card-border mt-4">
			<b-card-title>Add Maintenance
				<span class="numFont title">
					{{ form.maintenanceId }}
				</span>
			</b-card-title>
			<b-card-sub-title>Handles the creation of new maintenances for all companies </b-card-sub-title>
			<loading :active.sync="isLoading" loader="spinner" color="#20A8D8" :is-full-page="false" />

			<b-form class="mt-4" @submit.stop.prevent="handleSubmit" novalidate>
				<div role="tablist">
					<!-- PRIMARY DETAILS -->
					<b-card no-body class="mb-1">
						<b-card-header header-tag="header" class="p-1" role="tab">
							<b-btn block href="#" v-b-toggle.accordion-1 variant="primary"
								class="text-sm-left accordion-title">
								PRIMARY DETAILS
							</b-btn>
						</b-card-header>
						<b-collapse v-model="showFirstPane" id="accordion-1" accordion="my-accordion" role="tabpanel">
							<b-card-body>
								<b-row class="my-12">
									<b-col lg="4" md="6" sm="12">
										<b-form-group label="Dispatch ID" label-for="Dispatch ID">
											<v-select name="Dispatch ID" class="style-chooser" label="text"
												placeholder=" - Please select - " :options="dispatchOptions"
												:reduce="(dispatch) => dispatch.value" v-model="selDispatch"
												v-validate="'selectRequired'">
												<template v-slot:no-options="{ search, searching }">
													<template v-if="searching">
														No results found for
														<em>
															<strong>{{ search }}</strong>
														</em>
													</template>
													<em :style="{ opacity: 0.5 }" v-else>
														Start typing to search for a company
													</em>
												</template>
											</v-select>
											<span v-show="errors.has('Company')" class="help-block">
												{{ errors.first('Company') }}
											</span>
										</b-form-group>
										<b-form-group label="Asset Type" label-for="Asset Type">
											<v-select name="Asset Type" class="style-chooser" label="text"
												placeholder=" - Please select - " :options="assetTypeOptions"
												:reduce="(assetType) => assetType.value" v-model="selAssetType"
												v-validate="'selectRequired'">

												<template v-slot:no-options="{ search, searching }">
													<template v-if="searching">
														No results found for
														<em>
															<strong>{{ search }}</strong>
														</em>
													</template>
													<em :style="{ opacity: 0.5 }" v-else>
														Start typing to search for a location
													</em>
												</template>
											</v-select>
											<span v-show="errors.has('Storage Location')" class="help-block">
												{{ errors.first('Storage Location') }}
											</span>
										</b-form-group>
									</b-col>
									<b-col v-show="isNonNull(classification)" lg="4" md="6" sm="12" class="ml-4">
										<b-row class="my-2">
											<b-col sm="12">
												<b>Classification Details</b>
											</b-col>
										</b-row>
										<b-form-group label="Area" label-for="Area">
											<i class="fa fa-map-marker"></i>
											<i class="area-value">{{ getArea() }}</i>
										</b-form-group>
										<b-row class="my-2">
											<b-col sm="12">
												<b>Total: {{ totalAssetCount }}</b>
											</b-col>
										</b-row>
										<b-row class="my-2">
											<div class="classification-chart"> <!-- Set your desired dimensions here -->
												<canvas id="classification-chart"></canvas>
											</div>
										</b-row>
										<b-row class="my-2">
											<b-col sm="12">
												<b>Supporting Documents</b>
											</b-col>
										</b-row>
										<b-row class="my-2" v-if="isNonNull(classification)">
											<b-col sm="12">
												<div v-for="(document, index) in classification.documents" :key="index"
													class="document-file">
													<span v-if="document !== null">
														<i @click="onShowImage(document.url)">{{ document.name }}</i>
													</span>
												</div>
											</b-col>
										</b-row>
									</b-col>
								</b-row>
							</b-card-body>
						</b-collapse>
					</b-card>

					<!-- REPAIR DOCUMENTS -->
					<b-card v-if="isNonNull(classification)" no-body class="mb-1">
						<b-card-header header-tag="header" class="p-1" role="tab">
							<b-btn block href="#" v-b-toggle.accordion-2 variant="primary"
								class="text-sm-left accordion-title">
								REPAIR DOCUMENTS
							</b-btn>
						</b-card-header>
						<b-collapse id="accordion-2" accordion="my-accordion" role="tabpanel">
							<b-card-body>
								<b-row class="my-2">
									<b-col sm="12">
										<b-form-group>

											<template #description>
												<span style="font-style: italic;">Maximum upload of three (3) documents
													only</span>
											</template>
											<b>UPLOAD DOCUMENT(S)</b>
										</b-form-group>
									</b-col>
								</b-row>
								<b-row class="my-12">
									<b-col sm="4">
										<b-form-group label="Document 1"
											description="*32-bit PNG, 2000px by 2000px, up to 1 MB">
											<b-form-file id="document-file-0" placeholder="Choose image"
												ref="document-file-0" accept="image/png, image/jpeg, image/jpg"
												@change="onSelectDocument($event, 0)">

												<template slot="file-name" slot-scope="{ names }">
													<b-badge variant="dark">{{ names[0] }}</b-badge>
													<b-badge v-if="names.length > 1" variant="dark" class="ml-1">
														+ {{ names.length - 1 }} More files
													</b-badge>
												</template>
											</b-form-file>
										</b-form-group>
									</b-col>
									<b-col sm="4">
										<b-form-group label="Document 2"
											description="*32-bit PNG, 2000px by 2000px, up to 1 MB">
											<b-form-file id="document-file-1" placeholder="Choose image"
												ref="document-file-1" accept="image/png, image/jpeg, image/jpg"
												@change="onSelectDocument($event, 1)">

												<template slot="file-name" slot-scope="{ names }">
													<b-badge variant="dark">{{ names[0] }}</b-badge>
													<b-badge v-if="names.length > 1" variant="dark" class="ml-1">
														+ {{ names.length - 1 }} More files
													</b-badge>
												</template>
											</b-form-file>
										</b-form-group>
									</b-col>
									<b-col sm="4">
										<b-form-group label="Document 3"
											description="*32-bit PNG, 2000px by 2000px, up to 1 MB">
											<b-form-file id="document-file-2" placeholder="Choose image"
												ref="document-file-2" accept="image/png, image/jpeg, image/jpg"
												@change="onSelectDocument($event, 2)">

												<template slot="file-name" slot-scope="{ names }">
													<b-badge variant="dark">{{ names[0] }}</b-badge>
													<b-badge v-if="names.length > 1" variant="dark" class="ml-1">
														+ {{ names.length - 1 }} More files
													</b-badge>
												</template>
											</b-form-file>
										</b-form-group>
									</b-col>
								</b-row>
								<b-row class="my-12">
									<b-col sm="4">
										<!-- Image Preview -->
										<div v-if="isNonNullDocs(selDocuments[0])">
											<b-card overlay :img-src="selDocuments[0].url" img-alt="Card Image"
												text-variant="white" class="mb-0">
												<b-card-text class="text-right">
													<b-button variant="danger" size="sm" @click="onRemoveDocument(0)">
														<em class="fa fa-trash"></em>
													</b-button>
												</b-card-text>
											</b-card>
											<b-progress v-if="selDocuments[0].status === 'uploading'"
												:value="selDocuments[0].percentage" :max="selDocuments[0].name"
												variant="success" height="8px" animated />
										</div>
									</b-col>

									<b-col sm="4">
										<!-- Image Preview -->
										<div v-if="isNonNullDocs(selDocuments[1])">
											<b-card overlay :img-src="selDocuments[1].url" img-alt="Card Image"
												text-variant="white" class="mb-0">
												<b-card-text class="text-right">
													<b-button variant="danger" size="sm" @click="onRemoveDocument(1)">
														<em class="fa fa-trash"></em>
													</b-button>
												</b-card-text>
											</b-card>
											<b-progress v-if="selDocuments[1].status === 'uploading'"
												:value="selDocuments[1].percentage" :max="selDocuments[1].name"
												variant="success" height="8px" animated />
										</div>
									</b-col>

									<b-col sm="4">
										<!-- Image Preview -->
										<div v-if="isNonNullDocs(selDocuments[2])">
											<b-card overlay :img-src="selDocuments[2].url" img-alt="Card Image"
												text-variant="white" class="mb-0">
												<b-card-text class="text-right">
													<b-button variant="danger" size="sm" @click="onRemoveDocument(2)">
														<em class="fa fa-trash"></em>
													</b-button>
												</b-card-text>
											</b-card>
											<b-progress v-if="selDocuments[2].status === 'uploading'"
												:value="selDocuments[2].percentage" :max="selDocuments[2].name"
												variant="success" height="8px" animated />
										</div>
									</b-col>
								</b-row>

								<b-row class="mt-4">
									<b-col lg="4" md="4" sm="12">
										<b-form-group v-if="isNonNullDocs(selDocuments[0])" label="Remarks"
											label-for="Remarks" description>
											<b-form-textarea name="Document 1 Remarks" type="text"
												v-model="selDocuments[0].remarks" maxlength="200"
												v-validate="getValidationParam(true, remarksRegex)" :rows="3"
												placeholder="Remarks" />
											<span v-show="errors.has('Document 1 Remarks')" class="help-block">
												{{ errors.first('Document 1 Remarks') }}
											</span>
										</b-form-group>
									</b-col>
									<b-col lg="4" md="4" sm="12">
										<b-form-group v-if="isNonNullDocs(selDocuments[1])" label="Remarks"
											label-for="Remarks" description>
											<b-form-textarea name="Document 2 Remarks" type="text"
												v-model="selDocuments[1].remarks" maxlength="200"
												v-validate="getValidationParam(true, remarksRegex)" :rows="3"
												placeholder="Remarks" />
											<span v-show="errors.has('Document 2 Remarks')" class="help-block">
												{{ errors.first('Document 2 Remarks') }}
											</span>
										</b-form-group>
									</b-col>
									<b-col lg="4" md="4" sm="12">
										<b-form-group v-if="isNonNullDocs(selDocuments[2])" label="Remarks"
											label-for="Remarks" description>
											<b-form-textarea name="Document 3 Remarks" type="text"
												v-model="selDocuments[2].remarks" maxlength="200"
												v-validate="getValidationParam(true, remarksRegex)" :rows="3"
												placeholder="Remarks" />
											<span v-show="errors.has('Document 3 Remarks')" class="help-block">
												{{ errors.first('Document 3 Remarks') }}
											</span>
										</b-form-group>
									</b-col>
								</b-row>
							</b-card-body>
						</b-collapse>
					</b-card>
				</div>
			</b-form>
			<b-row>
				<b-col md="12" sm="12" class="my-1 text-sm-right">
					<b-button variant="secondary" @click="returnToMaintenanceMain" class="mr-2">
						Back
					</b-button>
					<b-button variant="primary" @click="handleOk" :disabled="isLoading">
						Save
					</b-button>
				</b-col>
				<b-col md="1" sm="1" class="my-1"></b-col>
			</b-row>
		</b-card>

		<!-- Modals here -->
		<ClassifyDispatchAssets :allAssetTypesObj="allAssetTypesObj" />
		<CreateRepairedBatch />
		<CheckRepairedBatch />
		<EndorseRepairedBatch />
		<ImageViewDialog />
		<AlertDialog />
	</div>
</template>

<script>
// Components
import ClassifyDispatchAssets from '../classification/ClassifyDispatchAssets';
import RepairedBatchRowActions from './RepairedBatchRowActions';
import CreateRepairedBatch from './CreateRepairedBatch';
import CheckRepairedBatch from './CheckRepairedBatch';
import EndorseRepairedBatch from './EndorseRepairedBatch';
import ImageViewDialog from '../common/ImageViewDialog';
import AlertDialog from '../common/AlertDialog';

// Utils
import { DateUtil } from '@/utils/dateutil';
import { DropDownItemsUtil } from '@/utils/dropDownItemsUtil';
import { ImageUtil } from '@/utils/imageUtil';
import { FileUtil } from '@/utils/fileUtil';
import { MaintenanceUtil } from '@/utils/maintenanceUtil';

// API & DAO
import maintenanceApi from '@/api/maintenanceApi';
import classificationDAO from '@/database/classifications';

// Others
import config from '@/config/env-constants';
import EventBus from '@/shared/event-bus';
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import { storage } from '@/config/firebase';
import Chart from 'chart.js';
import _ from 'lodash';

export default {
	name: 'add-maintenance',
	components: {
		Loading,
		ClassifyDispatchAssets,
		RepairedBatchRowActions,
		CreateRepairedBatch,
		CheckRepairedBatch,
		EndorseRepairedBatch,
		ImageViewDialog,
		AlertDialog,
	},
	data() {
		return {
			totalAssetCount: 0,
			showFirstPane: true,

			geoaddress: null,
			params: null,
			form: { ...MaintenanceUtil.getMaintenanceDefaultObj() },

			dispatchOptions: [],
			assetTypeOptions: [],

			selDispatch: { ...config.dispatchDefaultValue },
			selAssetType: { ...config.assetTypeDefaultValue },
			classification: {},
			selRepairCondition: {},
			selDocuments: [{}, {}, {}],

			allDispatchesObj: {},
			allAssetTypesObj: {},
			allClassifications: {},

			isSuperAdmin: this.$store.getters.isSuperAdmin,
			loggedUserCompany: this.$store.getters.loggedUserCompany,
			loggedUser: this.$store.getters.loggedUser,
			isViewer: this.$store.getters.isViewer,
			chart: null,
			ctxDOM: null,

			// Check for loader
			isLoading: false,
		};
	},
	computed: {
		maintenanceId() {
			return this.form && this.form.maintenanceId ? this.form.maintenanceId : '';
		},
		remarksRegex() {
			return config.remarksRegex;
		},
	},
	watch: {
		selDispatch: function (newVal) {
			if (newVal && newVal.id) {
				this.onChangeDispatch();
			}
		},
		selAssetType: async function (newVal) {
			if (newVal && newVal.id) {
				await this.onChangeAssetType(newVal.id);
			}
		},
	},
	mounted() {
		setTimeout(async () => {
			// Filter Access
			if (this.$store.getters.isViewer || this.$store.getters.isScanner) {
				this.$router.push('/dashboard');
				this.$toaster.warning('You are not allowed to access this page.');
			}

			await this.getCurrentLocation();

			// show loading indicator
			this.isLoading = true;

			// init parameters
			this.params = this.$store.getters.maintenanceParams;

			// Objects
			this.allDispatchesObj = this.params.allDispatchesObj;
			this.allAssetTypesObj = this.params.allAssetTypesObj;

			this.dispatchOptions = DropDownItemsUtil.retrieveDispatches(this.allDispatchesObj);
			this.assetTypeOptions = DropDownItemsUtil.retrieveAssetTypes(this.allAssetTypesObj, true);

			this.onReset();

			this.ctxDOM = document.getElementById('classification-chart');

			// hide loading indicator
			this.isLoading = false;
		}, config.timeout);
	},
	methods: {
		getValidationParam(isRequired, regex) {
			return {
				required: isRequired,
				regex: regex,
			};
		},
		async getCurrentLocation() {
			try {
				this.geoaddress = await this.$getCurrentLocation();
			} catch (error) {
				this.$toaster.error(
					'Error loading data. Please reload the page again.'
				);
			}
		},

		onChangeDispatch() {
			// reset storage fields
			const dispatch = this.allDispatchesObj[this.selDispatch.id];
			const assets = dispatch.assets;

			const assetTypesObj = {};
			for (const asset of assets) {
				assetTypesObj[asset.assetTypeId] = this.allAssetTypesObj[asset.assetTypeId];
			}
			this.assetTypeOptions = DropDownItemsUtil.retrieveAssetTypes(assetTypesObj, true);
			this.selAssetType = { ...config.assetTypeDefaultValue };
		},
		async onChangeAssetType(assetTypeId) {
			try {
				if (_.isEmpty(this.selDispatch)) return;

				// show loading indicator
				this.isLoading = true;

				this.classification = await classificationDAO.getClassificationByAssetType({
					id: this.selDispatch.id,
					dispatchId: this.selDispatch.dispatchId,
					assetTypeId: assetTypeId,
				});
				const assetType = this.allAssetTypesObj[assetTypeId];

				if (this.isNonNull(this.classification) && this.isNonNull(assetType)) {

					const conditions = this.classification.conditions.filter((condition) => {
						const assetCondition = assetType.conditions.find((item) => item.condition === condition.condition);
						return assetCondition && assetCondition.isBillable && assetCondition.isActive;
					});

					this.selRepairCondition = conditions.reduce((result, condition) => {
						result[condition.condition] = {
							name: condition.condition,
							quantity: condition.quantity,
							repaired: 0,
							checked: 0,
							endorsed: 0,
						};
						return result;
					}, {});

					const quantitiesArr = conditions.map((condition) => condition.quantity);
					const labelsArr = conditions.map((condition) => `${condition.handling} : ${condition.quantity}`);
					const colorsArr = [
						"rgb(18, 43, 145)",
						"rgb(228, 143, 1)",
						"rgb(12, 124, 89)",
						"rgb(228, 116, 119)",
						"rgb(166, 61, 64)",
					];

					this.totalAssetCount = quantitiesArr.reduce((accumulator, currentValue) => accumulator + currentValue, 0);
					this.createChart(labelsArr, quantitiesArr, colorsArr);

				} else if (this.isNonNull(assetType)) {

					await this.retrieveClassification();
					const classification = this.allClassifications[assetType.name];
					const dispatch = this.allDispatchesObj[this.selDispatch.id];

					const asset = dispatch.assets.find((item) => item.assetTypeId === assetType.id) || {};
					const params = {
						dispatch: dispatch,
						assetType: {
							id: asset.assetTypeId,
							name: asset.assetType,
							expected: asset.expectedQuantity,
							actual: asset.actualQuantity
						},
						classification: classification,
					};
					EventBus.$emit("onClassifyDispatchAssets", params);
					this.$bvModal.show("classify-dispatch-assets");
				}
			} catch (error) {
				console.error("Error:", error);
			} finally {
				// hide loading indicator
				this.isLoading = false;
			}
		},
		createChart(labelsArr, quantitiesArr, colorsArr) {
			this.chart = new Chart(this.ctxDOM, {
				type: "pie",
				data: {
					labels: labelsArr,
					datasets: [
						{
							data: quantitiesArr,
							borderWidth: 1,
							backgroundColor: colorsArr,
							fontSize: 20,
						},
					],
				},
				options: {
					legend: {
						position: "right",
						labels: {
							padding: 10,
							fontStyle: "bold",
						},
					},
					responsive: true, // Enable responsive sizing
					maintainAspectRatio: false, // Disable aspect ratio constraint
				},
			});
		},

		async retrieveClassification() {
			// show loading incicator
			this.isLoading = true;

			const classificationsObj = await classificationDAO.getClassificationsByDispatchId(this.selDispatch.id);
			const classificationsArr = Object.values(classificationsObj);

			this.allClassifications = {};
			for (const classification of classificationsArr) {
				this.allClassifications[classification.assetType] = classification;
			}

			// hide loading indicator
			this.isLoading = false;
		},

		async handleOk(evt) {
			// Prevent modal from closing
			evt.preventDefault();
			// show loading indicator
			this.isLoading = true;

			let isValid = await this.$validator.validateAll();
			if (!isValid) {
				this.$toaster.warning('Please address the field/s with invalid input');
				this.isLoading = false;
				return;
			}

			this.parseMaintenance();

			if (!this.isValid()) {
				// hide loading indicator
				this.isLoading = false;
				return;
			}

			await this.handleSubmit();
		},
		parseMaintenance() {
			// Update Maintenance Fields
			this.form.dispatchId = this.selDispatch.dispatchId;
			this.form.assetType = this.selAssetType ? this.selAssetType.name : '';
			this.form.assetTypeId = this.selAssetType ? this.selAssetType.id : '';

			// Area
			let area = this.classification.area;
			this.form.company = area.company;
			this.form.companyId = area.companyId;
			this.form.storageLocation = area.storageLocation;
			this.form.storageLocationId = area.storageLocationId;

			// Timestamps
			this.form.dateCreated = DateUtil.getCurrentTimestamp();
			this.form.createdBy = this.loggedUser.id;
			this.form.dateUpdated = DateUtil.getCurrentTimestamp();
			this.form.updatedBy = this.loggedUser.id;

			// Conditions
			this.form.conditions = [];
			for (const condition of this.classification.conditions) {
				this.form.conditions.push(condition);
			}

			// Documents
			this.form.documents = [];
			for (let i = 0; i < this.selDocuments.length; i++) {
				const document = this.selDocuments[i];
				if (!_.isEmpty(document)) {
					document.name = `DP_${this.form.maintenanceId}_repair_docs_${i}.jpg`;
					document.geoaddress = {
						latitude: this.geoaddress ? this.geoaddress.lat : 0,
						longitude: this.geoaddress ? this.geoaddress.lng : 0
					};
					this.selDocuments[i] = document;
					this.form.documents.push({
						...ImageUtil.createMedia(document),
						remarks: document.remarks
					});
				}
			}
		},
		isValid() {
			if (!this.form.dispatchId || this.form.dispatchId.length === 0) {
				this.$toaster.warning(`Maintenance ${this.form.maintenanceId} no Dispatch ID`);
				return false;
			} else if (!this.form.assetTypeId || this.form.assetTypeId.length === 0) {
				this.$toaster.warning(`Maintenance ${this.form.maintenanceId} no asset type`);
				return false;
			} else if (!this.form.conditions || this.form.conditions.length === 0) {
				this.$toaster.warning(`Maintenance ${this.form.maintenanceId} no asset classifications`);
				return false;
			} else if (!this.form.documents || this.form.documents.length === 0) {
				this.$toaster.warning(`Maintenance ${this.form.maintenanceId} has no supporting documents`);
				return false;
			}

			return true;
		},

		async handleSubmit() {
			let maintenanceId = this.form.maintenanceId;
			try {
				// run the add maintenance api
				let result = await maintenanceApi.addMaintenance(this.form, this.loggedUser.id);
				let data = result.data;

				if (data.isSuccess) {
					// Upload document forms
					await this.uploadDocuments(data.maintenance);

					this.$toaster.success(`New maintenance "${maintenanceId}" was created successfully.`);
					EventBus.$emit('onCloseSaveMaintenance', data.maintenance);

					// reset the state and revert to maintenance page
					this.returnToMaintenanceMain();

				} else {
					this.$toaster.warning(data.message);
				}

			} catch (error) {
				console.log(error);
				this.$toaster.error(`Error creating maintenance ${maintenanceId}. Please try again.`);
			} finally {
				// hide loading indicator
				this.isLoading = false;
			}
		},
		async uploadDocuments(maintenance) {
			for (let i = 0; i < this.selDocuments.length; i++) {
				const document = this.selDocuments[i];
				if (document && document.isNew) {
					const result = await this.firebaseUploadImage(document);
					if (result && result.name && result.url) {
						maintenance.documents[i].url = result.url;
						await maintenanceApi.updateMaintenance(
							maintenance,
							this.loggedUser.id
						);
					}
				}
			}
		},
		async firebaseUploadImage(document) {
			return new Promise((resolve, reject) => {
				let storageRef = storage.ref(`${document.fbStoragePath}/${document.name}`);
				let task = storageRef.put(document.file);

				task.on(
					'state_changed',
					() => { },
					(error) => {
						reject(error);
					},
					() => {
						task.snapshot.ref.getDownloadURL().then((downloadURL) => {
							resolve({
								name: document.name,
								url: downloadURL,
							});
						});
					}
				);
			});
		},
		returnToMaintenanceMain() {
			this.params.fromAddMaintenance = true;

			this.$store.dispatch('setMaintenanceParams', this.params);
			this.$store.dispatch('setCurrentMaintenance', {});

			if (!this.isSuperAdmin) {
				this.$router.push({ path: '/maintenance' });
			} else {
				this.$router.push({ path: '/admin/admin-maintenance' });
			}
		},

		onSelectDocument(evt, id) {
			const vm = this;
			const file = evt.target.files[0];

			if (!FileUtil.isValidImgFileType(file)) {
				this.$toaster.error(
					'Invalid File Type: Please import a valid document in PNG or JPEG format.'
				);
				return;
			}

			const url = URL.createObjectURL(file);
			let dimensions = { w: 0, h: 0 };

			const image = new Image();
			image.onload = function () {
				dimensions.w = image.width;
				dimensions.h = image.height;

				if (dimensions.w > 2000 || dimensions.h > 2000) {
					vm.$toaster.warning("Document's width and height shouldn't be greater than 2000 pixels");
				} else {
					vm.$set(vm.selDocuments, id, {
						url: url,
						file: file,
						fbStoragePath: 'images/maintenances',
						isNew: true,
						percentage: 0,
						status: '',
						remarks: '',
					});
				}
			};
			image.src = url;
		},
		onRemoveDocument(id) {
			this.$set(this.selDocuments, id, {});
			this.$refs[`document-file-${id}`].reset();
		},
		onShowImage(url) {
			let fileName = FileUtil.getUrlFileName(url);
			EventBus.$emit('onSelectImageView', {
				url: url,
				name: fileName
			});
			this.$bvModal.show('image-view-dialog');
		},

		onReset() {
			/* Reset our form values */
			this.form = { ...MaintenanceUtil.getMaintenanceDefaultObj() };

			// Set ID
			this.form.maintenanceId = 'MS' + DateUtil.getCurrentTimestamp();

			this.selDispatch = { ...config.dispatchDefaultValue };
			this.selAssetType = { ...config.assetTypeDefaultValue };

			// reset validation
			this.$validator.reset();
			this.errors.clear();
		},

		// UTILS
		isNonNullDocs(docs) {
			return docs && !_.isEmpty(docs) && docs.url;
		},
		isNonNullStr(str) {
			return str && str.length !== 0;
		},
		isNonNull(json) {
			return json && !_.isEmpty(json);
		},
		getArea() {
			if (!_.isEmpty(this.classification)) {
				const company = this.classification.area.company;
				const storageLocation = this.classification.area.storageLocation;
				return `${company} - ${storageLocation}`
			}
			return '-';
		},
	},
};
</script>

<style scoped>
.accordion-title {
	color: white !important;
}

.area-value {
	margin-left: 0.5em;
}

.classification-chart {
	width: 400px;
	height: 250px;
}

.document-file {
	font-weight: bold;
	cursor: pointer;
	color: #E48F01;
}

.rl-container {
	text-align: center;
}

.rl-container span {
	font-size: 4em;
	font-family: sans-serif;
}

.rl-container strong {
	color: #F86C6B;
}

.numFont {
	font-size: larger;
}
</style>
